<template>
  <div>
    <google-login-button v-if="!userIsBlocked && !loading" :client-id="clientId" @onError="onSignInError" @onSuccess="onSignInSuccess" />
    <span v-else-if="userIsBlocked && !loading" class="subtitle"> Usuário sem acesso a plataforma - requisitar acesso no canal #acessos_plataforma </span>
    <v-progress-linear v-else-if="loading" color="primary" indeterminate></v-progress-linear>
  </div>
</template>

<script>
import { enums } from '@mf-node/mf-vue-components'

import { mapActions } from 'pinia'
import { useStore as loginUseStore } from '@/modules/login/store'

import { getFingerprint } from '@/services/fingerprint.js'
import integrationService from '@/services/IntegrationService'
import { MUTATION_ADMIN_LOGIN, MUTATION_SELECT_USER_ACCOUNT } from '@/modules/login/graphql'
import GoogleLoginButton from './GoogleLoginButton.vue'

import { skipMultiUserSelection } from '@/helpers/skipMultiUserSelection'
import { getIndustryAccountIdBySource } from '@/helpers/industry'

export default {
  name: 'GoogleSigninButton',

  components: {
    GoogleLoginButton,
  },

  props: {
    source: {
      type: String,
      required: true,
      // validar se estão em maisculas
      validator: enums.authSources.getAuthSource,
    },
  },

  data: () => ({
    loading: false,
    userIsBlocked: false,
    clientId: '467972202441-6lu39vih2p6ou962s8f066gqq12s07h1.apps.googleusercontent.com',
  }),

  computed: {
    sourceLoginUrl() {
      return {
        ADMIN: 'web.mercafacil.com',
        STARLORD: 'starlord.mercafacil.com',
        STORYBOOK: 'storybook.mercafacil.com',
        AMBEV: 'ambev.mercafacil.com',
      }[this.source]
    },
  },

  methods: {
    ...mapActions(loginUseStore, ['getCode']),

    async onSignInSuccess(jwtCredentials) {
      this.loading = true

      const fingerprint = await getFingerprint()
      this.$setFingerPrint(fingerprint)

      const { data, userIsBlocked, error } = await this.$apollo
        .mutate({
          mutation: MUTATION_ADMIN_LOGIN,
          fetchPolicy: 'no-cache',
          variables: {
            sourceLoginUrl: this.sourceLoginUrl,
            token: jwtCredentials,
          },
        })
        .catch((err) => {
          if (err.graphQLErrors?.[0]?.message === 'authentication.error.user.has.no.access') {
            return { userIsBlocked: true }
          }
          return { error: err }
        })
      if (data) {
        if (skipMultiUserSelection(this.source)) {
          if (this.source === 'AMBEV') {
            await this.$apollo.mutate({
              mutation: MUTATION_SELECT_USER_ACCOUNT,
              fetchPolicy: 'no-cache',
              variables: {
                accountId: getIndustryAccountIdBySource(this.source),
              },
            })
          }

          const payload = {
            source: this.source,
            target: integrationService.getTarget(),
          }
          const { authCode } = await this.getCode(this.$apollo, payload)

          integrationService.redirectToIntegration(authCode)
        } else {
          const { adminLogin: { session_id } = {} } = data
          this.$emit('adminLogin', true, session_id)
        }
        this.$emit('toggleDialog', false)
      } else if (userIsBlocked) {
        this.userIsBlocked = true
      } else if (error) {
        console.error(error)
        this.$snackbar({ message: 'Falha ao acessar usuário.', snackbarColor: 'error' })
      }
      if (!['STORYBOOK', 'STARLORD'].includes(this.source)) this.loading = false
    },
    onSignInError() {
      this.$snackbar({
        text: 'Falha ao logar com Google ou você não tem permissão de acesso.',
        type: 'error',
      })
    },
  },
}
</script>
